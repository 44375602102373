import { Col, Row, Table } from "react-bootstrap"
import { useSelector } from 'react-redux';
import * as cn from 'classnames';
import {
    get
} from '../../../reducers/data.js';

import logo from "../../../icons/storepos.svg"
import checkmark from "../../../icons/icon/checkmark.svg"
import BannerImageComponent from "../banneImage/BannerImageComponent.jsx";
import useBreakpoint from "../../../custom-hooks/useBreakpoint.js";

import * as animations from "../../../animations"
import GriLottie from '../../dsy-inhouse/GriLottiePlayer';

const onlyIntegerEuroFormat = (number) => { 
    if (Number.isNaN(number)) return number;
    return Number.parseFloat(number)?.toFixed(0)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + " €";
  }
function DesktopStep ({number, copy, icon, active, past}){
    let ariaLabel = `Step numero ${number} ${copy}`
    if (active) ariaLabel += " in corso" 
    if (past) ariaLabel += " passato"
    return (
        <><div role="listitem"
            aria-hidden={false}
            className={ "desktop-step " + (active ? "active" : "") + 
                    (number === 6 ? " last" : "") + (past ? " past" : "")}>
            <li className="visually-hidden">{ariaLabel}</li>
            <div className="step-box" aria-hidden={true}>
                <div className={"step-number-box " + (past ? "past" : "") }>
                    <div className="step-number">
                        {icon ? <img src={icon} alt=""/> : 
                        <span  aria-hidden={true} >{number}</span>}
                    </div>
                </div>
            </div>
            <div aria-hidden={true} className="step-copy">{active ? copy: ""}</div>
        </div>
    </> 
    )
}

function Header({ title, subtitle, page, className,hideSubtitleMobile, anchor, main, imageId}) {
    const showStepper = page !== undefined
    const extraClass = showStepper ? " extra-margin" : ""
    const bp = useBreakpoint()
    const data = useSelector(get);
    const isDesktop = (bp ==="xxl" || bp ==="xl" || bp ==="lg")

    return (<>
        <header id="form-header" className={className +extraClass}>
        
            {imageId === "landing" ? <></> : <div id="tondo" className="d-none d-lg-block"></div> } 
            
            <div className={cn('title-box','p-md-4', {'title-box-landing': !showStepper})}>
                {/* LOGO */}
                <div>
                    <a href="https://enel.it" id="enel-logo" target="_blank" rel="noreferrer" >
                        <img src={logo} className="logo" alt="Vai alla homepage di Enel Energia" />
                    </a>
                </div>
                {
                    showStepper && 
                    <div className="progress-box d-lg-none">
                        <div className="my-3 d-flex">
                            <div className={"progress-block " + (page >= 0 ? "active" : "")} ></div>
                            <div className={"progress-block " + (page >= 1 ? "active" : "")} ></div>
                            <div className={"progress-block " + (page >= 2 ? "active" : "")} ></div>
                            <div className={"progress-block " + (page >= 3 ? "active" : "")} ></div>
                            <div className={"progress-block " + (page >= 4 ? "active" : "")} ></div>
                            <div className={"progress-block " + (page >= 5 ? "active" : "")} ></div>
                        </div>
                        <Row>
                            <Col xs={6} className="step">{page + 1} di 6</Col>
                        </Row>
                    </div>
                }

                <div className="title">
                    <h2 className="d-none d-lg-block" level="h1" level-size="xl">{title}</h2>
                    <div className={cn('d-lg-none','d-flex',{'flex-column': !isDesktop && !showStepper}, {'flex-row': !isDesktop && showStepper},'align-items-left')}>
                        <div className={cn('title-text', {'title-text-step': showStepper})}>{title}</div>
                        {!isDesktop && (imageId !== "landing" ? 
                             <BannerImageComponent pageTitle={title} imageId={imageId} extraClass="header-image"/> :
                             <div id="lottie-giorno-notte"><GriLottie animationData={animations.giornoNotte}/></div>)
                        }
                    </div>
                </div>
              
                {
                    showStepper && 
                    <div className="progress-desktop d-none d-lg-flex flex-row mt-3" role="list">
                        <DesktopStep past={page > 0} active={page === 0} number={1} copy="La tua casa" icon={page > 0 ? checkmark : undefined} />
                        <DesktopStep past={page > 1} active={page === 1} number={2} copy="Fonti energetiche" icon={page > 1 ? checkmark : undefined} />
                        <DesktopStep past={page > 2} active={page === 2} number={3} copy="Consumi attuali" icon={page > 2 ? checkmark : undefined} />
                        <DesktopStep past={page > 3} active={page === 3} number={4} copy="Report informativo" icon={page > 3 ? checkmark : undefined} />
                        <DesktopStep past={page > 4} active={page === 4} number={5} copy="Scelta delle nuove soluzioni" icon={page > 4 ? checkmark : undefined} />
                        <DesktopStep past={page > 5} active={page === 5} number={6} copy="Scelta delle nuove soluzioni" icon={page > 5 ? checkmark : undefined} />
                    </div>
                }  
                {isDesktop && (imageId !== "landing" ? 
                            <BannerImageComponent pageTitle={title} imageId={imageId} extraClass="side-image"/>:
                            <div id="lottie-giorno-notte"><GriLottie animationData={animations.giornoNotte}/></div>)
                }
              
            </div>
            { title === "Ecco quanto potresti risparmiare" && isDesktop && data['SpesaAnnuaAttuale'] && data['SpesaAnnuaFutura'] && <div id='box-risp-preliminare' >
                    <h2>STIMA DEL RISPARMIO TOTALE SULLA SPESA ANNUA</h2>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <p className='titolo f14 mb-0'>Attuale</p>
                            <p className='valore'>{onlyIntegerEuroFormat(data['SpesaAnnuaAttuale'])}</p>
                        </div>
                        <div>
                            <p className='titolo f14 mb-0'>Futura</p>
                            <p className='valore'>{onlyIntegerEuroFormat(data['SpesaAnnuaFutura'])}</p>
                        </div>
                        <div>
                            <p className='titolo f14 mb-0'>Risparmio</p>
                            <p className='valore'>{onlyIntegerEuroFormat(data['RisparmioAnnuo'])}</p>
                        </div>
                        <div className="percentage">
                            <p className=''>
                                {(parseFloat(data["RisparmioPerc"]) * 100).toFixed(0)}%
                                {/* -{Math.abs(Math.floor((parseFloat(data['RisparmioAnnuo'])) / parseFloat(data['SpesaAnnuaAttuale'].replace(".", "")) * 100)) + "%"} */}
                            </p>
                        </div>
                    </div>
            </div>}
        </header>
     </>);
}

export default Header;