import { DsyDivider } from "../dsy-wrappers/dsy-divider.js"
import utils from "../utils/index.js"
import optionsClima from "./climaai.json"
const sendProject = utils.sendProject
const sendExtra = utils.sendProjectFinal
const sendFoto = utils.sendFoto

const defaultNote = `<p>
<b> Prodotti Samsung sono disponibili esclusivamente tramite finanziamento con società finanziaria. </b> <br /><br />
<b>* Esempio di finanziamento per i non clienti Enel.</b> Fino a 120 mesi ‐ prima rata a 60 giorni ‐ importo finanziabile da € 580 a € 1.833,70. Esempio Samsung TV Crystal UHD 43”: € 508,00 (importo totale del credito) in 24 rate da €22,99 ‐ TAN fisso 7,50% TAEG 7,71%. Il TAEG rappresenta il costo totale del credito espresso in percentuale annua e include: interessi, spesa mensile gestione pratica € 0; importo totale dovuto (importo totale del credito + costo totale del credito) € 551,76 Messaggio pubblicitario con finalità promozionale. Per le informazioni precontrattuali richiedere sul punto vendita il documento “Informazioni europee di base sul credito ai consumatori” (SECCI) e copia del testo contrattuale. Salvo approvazione Agos Ducato S.p.A. Enel Energia opera quale intermediario del credito NON in esclusiva. L'importo del finanziamento dipende dal prezzo del bene come concordato tra cliente e Enel Energia in funzione di eventuali scontistiche applicate da Enel per l'adesione ad ulteriori offerte.</p>
                        <p><b> In più vantaggi esclusivi Se sei cliente Enel Energia o lo diventi: </b></p>
                            <ul>
                                <li>Prodotti ad alta efficienza a un prezzo scontato</li>
                                <li>Finanziamento a tasso zero TAN 0% TAEG 0%</li>
                                <li>Bonus complessivo di 60€ nelle tue bollette luce</li>
                                <li>Consegna al piano gratuita per tutti i prodotti</li>
                            </ul>
                                <p><b>Sconto sui prodotti, finanziamento a tasso zero e consegna al piano gratuita per il TV riservati ai clienti</b>
                                 che hanno sottoscritto un contratto di fornitura di energia elettrica e/o gas naturale nel mercato libero con Enel Energia, escluse le offerte PLACET luce e gas.
                                 </p>
                                 <p><b>Il bonus in bolletta</b> sarà riconosciuto ai clienti che hanno sottoscritto un contratto di fornitura di energia elettrica e/o gas naturale nel mercato libero con Enel Energia, escluse le offerte Placet (luce) e offerte del Servizio a Tutele Graduali per i clienti domestici non vulnerabili. Il bonus sarà riconosciuto dopo l’approvazione da parte della finanziaria e a seguito della consegna del prodotto, e ripartito come segue: 20€ nella prima bolletta utile decorso un mese dalla consegna, 40€ nella prima bolletta utile decorsi 13 mesi dalla consegna.</p>
                                 
                               <p> ** I risultati del test si basano sul confronto tra la temperatura come da impostazioni di fabbrica con modalità AI Energy e senza modalità AI Energy. I risultati possono variare a seconda delle condizioni e delle modalità di utilizzo. </p>
<p>***  Il consumo energetico di questo modello di [frigorifero con congelatore RB(L)38C***W**] è di 87kw/h/anno, con un'efficienza energetica superiore del 20% rispetto alla soglia minima della classe di efficienza energetica A (108,40 kWh/anno) per questo prodotto. </p>` 

const data = {
    "pages": [
        {
            "title": "Raccontaci della tua casa",
            "image": "italy",
            "items": [
                {
                    "name":"Indirizzo",
                    "type":"address",
                    "cell": "Indirizzo",
                    "desktopPage":1
                },
                {
                    "type":"divider",
                    "desktopPage": 1,
                },
                {
                    "name": "Piccola o spaziosa? Storica o moderna?",
                    "description": "Condividi con noi qualche dettaglio sulla tua casa",
                    "type": "label",
                    "component": "h2",
                    "extraClass": "mb-16 mt-0",
                    "desktopPage": 1
                },
                {
                    "name": "Tipologia edificio",
                    "cell": "C11",
                    "type": "choicebuttons",
                    "choices": [
                        "Monofamiliare",
                        "Bifamiliare/Villette a schiera",
                        "Piccolo Condominio (2-8 abitazioni)",
                        "Medio Condominio (9-15 abitazioni)",
                        "Grande Condominio (oltre 16 abitazioni)"
                    ],
                    "desktopPage": 1
                },
                {
                    "name":"È presente un impianto fotovoltaico",
                    "cell": "PresenzaFotovoltaico",
                    "type": "bool",
                    // "conditionalRendering": 
                    // [
                    //         {
                    //             "cell": "C11",
                    //             "operator": "equal",
                    //             "value": "Monofamiliare"
                    //         },
                    //         {
                    //             "cell": "C11",
                    //             "operator": "equal",
                    //             "value": "Bifamiliare/Villette a schiera"
                    //         }
                    // ],
                    "desktopPage": 1
                },
                  {
                    "name":"Sono presenti uno o più balconi",
                    "cell": "PresenzaBalcone",
                    "type": "bool",
                    "announceWhen": true,
                    "announceMessage":"Nuovo componente disponibile per la selezione dei balconi",
                    "desktopPage": 1
                },
                 {
                    "name": "Tipologia balconi",
                    "cell": "TipologiaBalconi",
                    "type": "choicebuttons",
                    "choices": [
                        "Ringhiera",
                        "Muratura"
                    ],  
                    "conditionalRendering": 
                        [
                             {
                                 "cell": "PresenzaBalcone",
                                 "operator": "equal",
                                 "value": true
                             }
                        ],
                    "desktopPage": 1
                },
                {
                    "name":"Casa ristrutturata negli ultimi 10 anni",
                    "cell": "RistrutturatoUltimi10Anni",
                    "extraClass": "",
                    "type": "bool",
                    "info": {
                        "title":"Cosa intendiamo per ristrutturazione della casa",
                        "subtitle":"Per <b>'casa'</b> intendiamo <b>l'immobile in cui il proprietario e i membri del suo nucleo familiare risiedono.</b> Può trattarsi di una casa, un appartamento, una villa o qualsiasi altro tipo di <b>edificio residenziale.</b> <br />  <br /> In questo contesto, una <b>ristrutturazione si riferisce a interventi eseguiti negli ultimi 10 anni</b> che migliorano <b>l'efficienza energetica</b> dell'immobile.  <br />  <br />Questo include lavori eseguiti all'interno dell'abitazione, come la <b>sostituzione degli infissi</b> con modelli a doppio o triplo vetro, l'installazione di <b>impianti di riscaldamento a condensazione</b> o <b>pompe di calore</b>, nonché <b>l'isolamento delle pareti e dei tetti</b> per ridurre le dispersioni termiche. <br />  <br />A livello esterno, si considerano <b>interventi sulla facciata dell'edificio</b>, come l'applicazione di un <b>cappotto termico</b> o altre forme di <b>coibentazione</b>."
                    },
                    "announceWhen": 
                        { 
                            true: "Nuovo componente disponibile per la selezione della coibentazione dell'edificio",
                            false: "Nuovo componente disponibile per la selezione dell'anno di costruzione dell'edificio"
                        },
                    "desktopPage": 1
                },
                // {
                //     "name":"Condominio/edificio coibentato",
                //     "description": "La coibentazione consiste in interventi che isolano l’edificio impedendo scambi di calore che possono generare dispersione termica.",
                //     "cell": "Coibentato",
                //     "type": "bool",
                //      "conditionalRendering": 
                //         [
                //              {
                //                  "cell": "RistrutturatoUltimi10Anni",
                //                  "operator": "equal",
                //                  "value": true
                //              }
                //         ],
                //         "desktopPage": 1
                // },
                {
                    "name": "Anno di costruzione",
                    "cell": "C10",
                    "type": "choicebuttons",
                    "above":true,
                    "extraClass":"mt-3",
                    "choices": [
                        "Non so",
                        "Prima del 1919 e non ristrutturata di recente",
                        "1919-1945 e non ristrutturata di recente",
                        "1946-1960 e non ristrutturata di recente",
                        "1961-1980 e non ristrutturata di recente",
                        "1981-1990 o recentemente ristrutturata senza coibentazione",
                        "1991-2005",
                        "Dopo il 2005 o ristrutturata con coibentazione"
                    ],
                    "conditionalRendering": [{
                        "cell": "RistrutturatoUltimi10Anni",
                        "operator": "notequal",
                        "value": true
                    }],
                    "desktopPage": 1
                },
                {
                    "name": "Classe energetica",
                    "cell": "C36",
                    "above":true,
                    "type": "choicebuttons",
                    "choices": [
                        "Non so",
                        "A4",
                        "A3",
                        "A2",
                        "A1",
                        "B",
                        "C",
                        "D",
                        "E",
                        "F",
                        "G"
                    ],
                    "info": {
                        "title":"Come reperire l'informazione sulla classe energetica della casa?",
                        "subtitle":"Per ottenere informazioni sulla classe energetica della tua casa, hai diverse opzioni a disposizione:<br/><br/><ol><li><b>Attestato di Prestazione Energetica (APE):</b> Questo documento è fondamentale per la vendita o l’affitto di immobili in Italia, e qui troverai chiaramente indicata la classe energetica della tua abitazione. Se non hai l’APE, puoi richiederlo a un tecnico abilitato—come un ingegnere, un architetto o un geometra—che si occuperà di effettuare una valutazione energetica dell'edificio.</li><li><b>Certificazione Energetica del Costruttore:</b> Se la tua casa è di recente costruzione o è stata ristrutturata, il costruttore o l'impresa che ha eseguito i lavori e che ha venduto la casa potrebbe averti fornito una certificazione energetica.</li><li></b>Documenti della Compravendita:</b> Se hai acquistato la casa, controlla nei documenti di compravendita o nel contratto di affitto; la classe energetica è spesso indicata lì.</li><li><b>Richiesta di Valutazione:</b> Se non possiedi l’APE e la classe energetica non è mai stata valutata, puoi contattare un professionista abilitato per eseguire una diagnosi energetica. Il tecnico misurerà il consumo energetico dell’immobile e ti rilascerà l’APE.</li> <li><b>Portali Immobiliari:</b> Se hai acquistato o affittato la casa tramite un’agenzia, potresti trovare la classe energetica riportata nei portali immobiliari, dove è spesso inclusa tra le informazioni standard.</li></ol><br/>In breve, l’APE rimane il documento ufficiale e più affidabile per conoscere la classe energetica della tua abitazione."
                    },
                    "desktopPage": 1
                },
                {
                    "name": "A che piano si trova?",
                    "description": "Sei al piano terra con i gatti nel cortile o più in alto ad ammirare gli uccelli?",                                                                                               
                    "cell":  "C12",                                                                                                                                                                                                                        
                    "type": "choiceatview",
                    "choices": [
                    {
                        "text": "Ultimo piano",
                        "image":2,
                        "value":"Ultimo piano"
                    },
                    {
                        "text": "Piano intermedio",
                        "image": 1,
                        "value":"Piano intermedio"
                    },
                   {
                        "text": "Piano terra",
                        "image": 0,
                        "value":"Piano terra"
                    }], 
                    "conditionalRendering": 
                    [
                         {
                             "cell": "C11",
                             "operator": "equal",
                             "value":  "Piccolo Condominio (2-8 abitazioni)",
                         },
                          {
                             "cell": "C11",
                             "operator": "equal",
                             "value":  "Medio Condominio (9-15 abitazioni)",
                         },
                         {
                            "cell": "C11",
                            "operator": "equal",
                            "value":   "Grande Condominio (oltre 16 abitazioni)",
                        }
                    ],
                    "desktopPage": 2
                },
                {
                    "name": "Quanto è grande (metri quadri)?",
                    "placeholder": "Dimensione casa (metri quadri)",
                    "cell": "C13",
                    "type": "slider",
                    "unit": "m2",
                    "min": 20,
                    "max": 500,
                    "info": {
                        "title":"Dimensione della tua casa",
                        "subtitle":"<b>La dimensione minima consentita dalla normativa per un'abitazione</b>, comprensiva dei servizi (come bagni e cucine), <b>non può essere inferiore a 20 metri quadri.</b> <br /> <br />Se invece possiedi <b> una casa superiore a 500 metri quadri, seleziona comunque 500</b>: il nostro algoritmo effettuerà una simulazione adeguata e precisa."
                    },
                    "extraClass": "",
                    "markers": [
                        "28",
                        "100",
                        "200",
                        "300",
                        "400",
                        "500"
                    ],
                    "desktopPage": 3
                },
                {
                    "type":"divider",
                    "desktopPage": 3,
                },
                {
                    "name": "Quanti locali ha?",
                    "placeholder": "Locali totali",
                    "description": "Puoi selezionare fino ad un massimo di 20 locali",
                    "info": {
                        "title":"Cosa si intende per locali?",
                        "subtitle":"Per locali si intendono spazi come <b>camere da letto, studi</b> e <b>saloni</b>, mentre sono <b>esclusi i servizi</b>, come i bagni, le cucine e altre aree funzionali della casa."
                    },
                    "cell": "C16",
                    "type": "stepper",
                    "min": 1,
                    "step": 1,
                    "max": 20,
                    "defaultValue":1,
                    "text":"Locali",
                    "icon": "casa",
                    "markers": [
                        "1",
                        "20"
                    ],
                    "desktopPage": 3
                },
                {
                    "type":"divider",
                    "desktopPage": 3,
                },
                {
                    "name": "Quante persone ci vivono in totale?",
                    "placeholder": "Persone totali",
                    "description":"Puoi selezionare fino ad un massimo di 20 inquilini",
                    "cell": "C15",
                    "type": "stepper",
                    "min": 1,
                    "defaultValue":1,
                    "step": 1,
                    "max": 20,
                    "text":"Inquilini",
                    "icon": "inquilini",
                    "markers": [
                        "1",
                        "20"
                    ],
                    "desktopPage": 3
                },
                // {
                //     "name": "Quanti mesi all’anno è abitata la casa?",
                //     "cell": "C17",
                //     "type": "stepper",
                //     "step": 1,
                //     "icon": "mesi",
                //     "text": "Mesi",
                //     "min": 1,
                //     "max": 12,
                //     "defaultValue":12,
                //     "markers": [
                //         "1",
                //         "12"
                //     ],
                   
                //     "desktopPage": 5
                // },
                // {
                //     "name": "La casa è prevalentemente abitata nel periodo invernale",
                //     "cell": "C18",
                //     "type": "bool",
                //     "conditionalRendering": 
                //     [
                //          {
                //              "cell": "C17",
                //              "operator": "notequal",
                //              "value": "12"
                //          }
                //     ],
                //     "desktopPage": 5
                // },
                {
                    "type": "label",
                    "name": "Come rendi più fresca la tua casa in estate?",
                    "component": "h2",
                    "desktopPage": 4
                },
                {
                    "name": "Ho dei climatizzatori",
                    "cell": "C19",
                    "type": "bool",
                    "info": {
                        "title":"Cosa intendiamo per climatizzatori",
                        "subtitle":"Quando ti chiediamo se hai un climatizzatore a casa, ci riferiamo a un apparecchio che utilizza un'<b>unità esterna (il motore) collegata a uno o più un’unità interne (gli split)</b>. Questi dispositivi sono progettati per raffreddare o riscaldare l'aria, mantenendo una temperatura confortevole in casa. <br /> <br /> <b>Non ci riferiamo a deumidificatori, ventilatori, o altri dispositivi che semplicemente muovono l'aria o riducono l'umidità</b> senza modificare la temperatura in modo significativo."
                    },
                    "announceWhen": true,
                    "announceMessage":"Nuovo componente disponibile per la funzione di riscaldamento",
                    "component": "h2",
                    "desktopPage": 4
                },
               
                {
                    "name": "I climatizzatori hanno anche funzione riscaldamento",
                    "cell": "CondizionatoriRiscaldamento",
                    "type": "bool",
                    // "disableIf": {
                    //     "cell": "C19",
                    //     "operator":"equal",
                    //     "value": false
                    // },
                    "conditionalRendering":[{
                        "cell": "C19",
                        "operator":"equal",
                        "value": true
                    }],
                    "desktopPage": 4
                },
                {
                    "type": "divider",
                    "desktopPage": 4,
                },
                {
                    "name": "Hai elettrodomestici più vecchi di 10 anni?",
                    "cell": "EV",
                    "type": "multichoice",
                    "choices": [
                        {
                            "text": "Frigorifero",
                            "image": 4,
                            "cell": "Frigorifero"
                        },
                        {
                            "text": "Lavatrice",
                            "image": 5,
                            "cell": "Lavatrice"
                        },
                        {
                            "text": "Asciugatrice",
                            "image": 6,
                            "cell": "Asciugatrice"
                        },
                        {
                            "text": "Televisore",
                            "image": 7,
                            "cell": "TV"
                        },
                    ],
                    "conditionalRendering": {
                        "cell": "process.env.REACT_APP_PRODOTTI_SAMSUNG",
                        "operator": "equal",
                        "value": "true"
                    },
                    "required": false,
                    "desktopPage": 4
                }  
            ]
        },
        {
            "title": "Le tue fonti di energia",
            "items": [
                {
                    "type": "label",
                    "name": "Quali fonti energetiche utilizzi?",
                    "description": "La storia della tua casa ci sta appassionando!",
                    "desktopPage": 1,
                    "component": "h2",
                    "extraClass": "mt-0"
                },
                {
                    "name": "Il riscaldamento è centralizzato",
                    "cell": "C24",
                    "type": "bool",
                    "component": "h2",
                    "announceWhen": false,
                    "announceMessage":"Nuovo componente disponibile per la selezione della fonte energetica utilizzata per il riscaldamento",
                    "desktopPage": 1
                },
                {
                    "name": "Fonte di energia per il riscaldamento",
                    "cell": "C25",
                    "type": "choicebuttons",
                    "choices": [
                        "Metano",
                        "GPL",
                        "Aria Propanata",
                        "Gasolio",
                        "Pellet",
                        "Legna secca",
                        "Elettrico"
                    ],
                    "conditionalRendering": [{
                        "cell": "C24",
                        "operator": "notequal",
                        "value": true
                    }],
                    "desktopPage": 1
                },
                {
                    "name": "Fonte di energia per l'acqua calda",
                    "cell": "C26",
                    "type": "choicebuttons",
                    "choices": [
                        "Metano",
                        "GPL",
                        "Aria Propanata",
                        "Gasolio",
                        "Pellet",
                        "Legna secca",
                        "Elettrico"
                    ],
                    "desktopPage": 1
                },
                {
                    "name": "Fonte di energia del piano cottura cibi",
                    "cell": "C27",
                    "type": "choicebuttons",
                    "choices": [
                        "Metano",
                        "GPL",
                        "Aria Propanata",
                        "Elettrico"
                    ],
                    "desktopPage": 1
                },
              
                // {
                //     "name": "Ho il serbatoio GPL interrato",
                //     "cell": "C28",
                //     "type": "bool",
                //     "conditionalRendering": 
                //     [
                //          {
                //              "cell": "C27",
                //              "operator": "equal",
                //              "value": "GPL"
                //          },
                //          {
                //             "cell": "C26",
                //             "operator": "equal",
                //             "value": "GPL"
                //         },
                //         {
                //             "cell": "C25",
                //             "operator": "equal",
                //             "value": "GPL"
                //         }
                //     ],
                //     "desktopPage": 1
                // },
                {
                    "name": "C'è il solare termico",
                    "cell": "C29",
                    "type": "bool",
                    "component": "h2",
                    "desktopPage": 1,
                    "info": {
                        "title":"Cosa intendiamo per solare termico",
                        "subtitle":"Il solare termico è un sistema che <b>sfrutta l'energia del sole per riscaldare l'acqua</b>. I pannelli solari, solitamente montati sui <b>tetti</b>, catturano la luce solare e la <b>convertono in calore</b>, che viene poi utilizzato per scaldare l'acqua."
                    }
                }, 
                {
                    "type":"gasdisclaimer",
                    "desktopPage": 1
                },
                // {
                //     "name": "Conosci la potenza del tuo contatore?",
                //     "cell": "C61",
                //     "type": "bool",
                //     "info": {
                //         "title":"Non conosci la tua attuale potenza?",
                //         "subtitle":"La potenza (kW) è un dato facoltativo. Se non conosci la tua potenza (kW) attiva l’opzione “non la conosco” e procedi con la simulazione. Utilizzeremo un valore di potenza medio come valore di calcolo. "
                //     },
                //     "desktopPage": 2
                // }, 
                {
                    "name": "Qual è la potenza del tuo contatore?",
                    "facsimile": {
                        "image": 0,
                        "content": "potenzaContatore",
                        "title":"Ecco come puoi recuperare questa informazione",
                        "subtitle": "Nell'esempio qui sotto ti mostriamo dove trovare questo dato.",
                        "html": `<div >
                         <b> Pagina 2 della bolletta</b> <br />
                         Se non riesci a trovare le informazioni, consulta la <b><a aria-label="Leggi la guida ufficiale in pdf sul sito Enel Energia, il collegamento apre in una nuova scheda" href="https://www.enel.it/content/dam/enel-it/documenti-supporto/tutto-sulla-bolletta/documenti/guida-bolletta-enel-energia.pdf" target="_blank"> guida alla lettura della bolletta</a></b>
                        </div>`
                    },
                    "type": "slider",
                    "cell": "D61",
                    "unit": "kW",
                    "min": 3,
                    "max": 10,
                    "step": 0.5,
                    "defaultValue": 3,
                    "component": "h2",
                    "cellSiNo": "C61",
                    "cellSiNoCopy": {
                        title:"Se non sai la potenza, ci pensiamo noi!",
                        subtitle:"Se non riesci a trovare questa informazione, puoi selezionare l’opzione “Non lo so” e saltare questo passaggio. Utilizzeremo il valore di potenza più diffuso (3 kW)."
                    },
                    // "info": {
                    //     "title":"Non conosci la tua attuale potenza?",
                    //     "subtitle":"La potenza è una informazione facoltativa. Se non la conosci, seleziona l’opzione “Non lo so” e procedi con la simulazione. Utilizzeremo il valore di potenza più diffuso (3 kW)"
                    // },
                    "sr": "Non conosco la potenza del mio contatore",
                    // "conditionalRendering":[{
                    //     "cell": "C61",
                    //     "operator": "equal",
                    //     "value": true
                    // }],
                    "disableIf": {
                        "cell": "C61",
                        "operator": "equal",
                        "value": "No"
                    },
                    // "extraClass": "force-mobile",
                    // "canBeNull":"true",
                    "desktopPage": 2
                }, 
               
              
            ]
        },
        {
            "title": "Capiamo quanta energia usi in un anno!",
            "items": [
                {
                    "type": "label",
                    "name": "<h2 class='fonti-text bold-title'>Quanto spendi ogni anno per le varie fonti energetiche?</h2>",
                    "desktopPage": 1,
                    "extraClass":"no-margin"     
                },
                {
                    "type": "percentage",
                    "name": "",
                    "cell": "C31",
                    // "subtitle": "Senza canone RAI",
                    "canBeNull": true,
                    // "after": "Se non riesci a trovare tutte le informazioni sulla tua spesa puoi selezionare l'opzione \"Non la conosco\" e saltare questo passaggio. Utilizzeremo una stima basata sulle informazioni relative all'abitazione.",
                    "desktopPage": 1                    
                },
              
               
                {
                    "name": "Qual è il tuo consumo elettrico annuo?",
                    "type": "slider",
                    "unit": "kWh/a",
                    "cell": "C35",
                    "min": 0,
                    "max": 15000,
                    "step": 100,
                    // "info": {
                    //     "title":"Non conosci il tuo consumo elettrico annuo?",
                    //     "subtitle":"Il consumo elettrico (kWh/a) è un dato facoltativo. Se non conosci il tuo consumo disattiva l'opzione \"Conosco il mio consumo elettrico annuo\" e procedi con la simulazione. Utilizzeremo una stima basata sulle informazioni relative all'abitazione"
                    // },
                    "sr": "Non conosco il mio consumo energetico annuo",
                    "cellSiNoCopy": {
                        title:"Se non sai il consumo, ci pensiamo noi!",
                        subtitle:"Se non riesci a trovare tutte le informazioni sul tuo consumo, puoi selezionare l’opzione “Non lo so” e saltare questo passaggio. Utilizzeremo una stima basata sulle informazioni relative all’abitazione."
                    },
                    // "conditionalRendering":[{
                    //     "cell": "C34",
                    //     "operator": "equal",
                    //     "value": true
                    // }],
                    "facsimile": {
                        "type":"facsimile",
                        "content":"consumoAnnuo",
                        "title":"Ecco come puoi recuperare questa informazione",
                        "subtitle": "Nell'esempio qui sotto ti mostriamo dove trovare questo dato.",
                        "desktopPage":2,
                        "image": 2
                       },
                    "disableIf": {
                         "cell": "C34",
                         "operator": "equal",
                         "value": "No"
                    },
                    "cellSiNo": "C34",
                    "canBeNull":"true",
                    "desktopPage": 2
                },
                
                //    {
                //     "name": "Conosco il consumo elettrico annuo dell'abitazione",
                //     "cell": "C34",
                //     "type": "bool",
                //     "desktopPage": 2
                // }, 
            ],
            "onSubmit": sendProject
        },
        {
            "title": "Ecco quanto potresti risparmiare",
            "showBack": false,
            "nextText": "Continua",
            "items": [{
                "type":"summary",
                "desktopPage": 1,
                "riepilogo": false
            }]
        },
        {
            "title": "Configura come vuoi in base alle tue esigenze",
            "hideSubtitleMobile": true,
            "items": [
                // {
                //     "type": "label",
                //     "name": "Per che cosa vorresti ricevere un'offerta?",
                //     "desktopPage": 1,
                //     "extraClass": "d-lg-none no-margin my-3"     
                // },
                // {
                //     "type": "label",
                //     "component": "h2",
                //     "name":"Per che cosa vorresti ricevere un'offerta?",
                //     "desktopPage": 1,
                //     "extraClass": "no-margin my-3"
                // },
                {
                    "type": "multichoice",
                    "name": "Prodotti",
                    "required": true,
                    "choices": [
                        {
                        "text": "Riscaldamento",
                        "image": 0,
                        "cell": "C41",
                        "risp":"Risc_Risparmio",
                        "conditionalRendering":[{
                            "cell": "C24",
                            "operator": "notequal",
                            "value": true
                        }, 
                        {
                            "cell": "C25",
                            "operator": "notequal",
                            "value": "Elettrico"
                        }]
                    },
                    // {
                    //     "text": "Acqua calda sanitaria",
                    //     "image": 1,
                    //     "cell": "C45",
                    //     "risp":"AC_Risparmio",
                    //     "conditionalRendering":[
                    //     {
                    //         "cell": "C26",
                    //         "operator": "notequal",
                    //         "value": "Elettrico"
                    //     }]
                    // },
                    {
                        "text": "Piano Cottura Cibi",
                        "image": 8,
                        "cell": "C48",
                        "risp":"Cott_Risparmio",
                        "conditionalRendering":[
                            // {
                            //      "cell": "C48",
                            //      "operator": "must",
                            //      "value": true
                            // },
                            // {
                            //     "cell": "process.env.REACT_APP_PRODOTTI_SAMSUNG",
                            //     "operator": "must",
                            //     "value": "true"
                            // },
                            {
                                "cell": "C27",
                                "operator": "notequal",
                                "value": "Elettrico"
                            }]
                    },
                    {
                        "text": "Lavatrice",
                        "image": 5,
                        "cell": "Final_Lavatrice",
                        "risp":"Lavatrice",
                        "conditionalRendering":[
                            {
                                "cell": "Lavatrice",
                                "operator": "equal",
                                "value": "Si"
                            }]
                    },
                    {
                        "text": "Televisore",
                        "image": 7,
                        "cell": "Final_TV",
                        "risp":"TV",
                        "conditionalRendering":[
                            {
                                "cell": "TV",
                                "operator": "equal",
                                "value": "Si"
                            }]
                    },
                    {
                        "text": "Asciugatrice",
                        "image": 6,
                        "cell": "Final_Asciugatrice",
                        "risp":"Asciugatrice",
                        "conditionalRendering":[
                            {
                                "cell": "Asciugatrice",
                                "operator": "equal",
                                "value": "Si"
                            }]
                    },
                    {
                        "text": "Frigorifero",
                        "image": 4,
                        "cell": "Final_Frigorifero",
                        "risp":"Frigorifero",
                        "conditionalRendering":[
                            {
                                "cell": "Frigorifero",
                                "operator": "equal",
                                "value": "Si"
                            }]
                    },
                    {
                        "text": "Energia solare",
                        "image":3,
                        "cell": "C53"
                    },
                    // {
                    //     "text": "Energia solare virtuale",
                    //     "image":3,
                    //     "cell": "FotoVirtuale",
                    //     "conditionalRendering": [
                    //         {
                    //             "cell": "process.env.REACT_APP_FOTO_VIRTUALE",
                    //             "operator": "equal",
                    //             "value": "true"
                    //         },
                    //         {
                    //             "cell": "C11",
                    //             "operator": "notin",
                    //             "value": [     
                    //                 "Piccolo Condominio (2-8 abitazioni)",
                    //                 "Medio Condominio (9-15 abitazioni)",
                    //                 "Grande Condominio (oltre 16 abitazioni)"
                    //             ]
                    //         },
                    //         {
                    //             "cell":"FornituraEnel",
                    //             "operator": "equal",
                    //             "value": false
                    //         },
                    //          {
                    //              "cell": "PresenzaBalcone",
                    //              "operator": "equal",
                    //              "value": "No"
                    //          }
                    //     ]
                    // },
                ],
                    "desktopPage": 1                    
                },
            ],
            "onCheck": sendFoto
        },
        {
            "title": "Riscaldamento",
            "page": 4,
            "conditionalRendering": 
                    [
                         {
                             "cell": "C41",
                             "operator": "equal",
                             "value": true
                         }
                    ],
            "items":[
                // {
                //     "type":"label",
                //     "name": "In base alle informazioni fornite sull'abitazione, ti proponiamo:",
                //     "desktopPage": 1,
                //     "extraClass":"mb-0"
                // },
                {
                    "type": "riscaldamento",
                    "desktopPage": 1
                },
                {
                    "name": "",
                    "extraClass": "my-16",
                    "type": "spacer",
                    "desktopPage": 1   
                },
                {
                    "name": "Qual è l'investimento più adatto a te?",
                    "type": "label",
                    "desktopPage": 1   
                },
                {
                    "name": "Opzioni di investimento?",
                    "cell": "PrestazioneRiscaldamento",
                    "type": "choicebuttons",
                    "above":true,
                    "choices": [
                        "Più conveniente €",
                        "Miglior rapporto qualità/prezzo €€",
                        "Top di gamma €€€"
                    ],
                    "desktopPage": 1   
                }, 
                {
                    "type": "small-note",
                    "text": "* Il prezzo visualizzato può variare in base alla regione in cui si trova la tua casa. Non preoccuparti, il sopralluogo tecnico-commerciale (per la verifica di fattibilità dell’installazione) e l'installazione sono inclusi nel prezzo.",
                    "desktopPage": 1
                }, 
                // {
                //     "type": "small-note",
                //     "text": "** Sono disponibili diverse modalità di finanziamento. Per conoscere i requisiti di accesso alle agevolazioni, il periodo di validità e le spese ammissibili, consulta la normativa di riferimento o parla con il nostro consulente nel punto vendita. Sarà felice di darti tutte le informazioni necessarie per aiutarti a comprendere il costo finale del prodotto che desideri. Non dimenticare di richiedere il documento “Informazioni europee di base sul credito ai consumatori” (SECCI) e una copia del contratto, così avrai tutto il necessario per prendere una decisione informata sui costi e sulle condizioni.",
                //     "desktopPage": 1
                // }
            ]
        },
        {
            "title": "Acqua calda",
            "page": 4,
            "conditionalRendering": 
            [
                 {
                     "cell": "C45",
                     "operator": "equal",
                     "value": true
                 }
            ],
            "items":[
                {
                    "type": "label",
                    "name":"Tipo di soluzione <br />In base alle informazioni fornite sull'abitazione ti proponiamo:",
                    "cell": "",
                    "component": "h2",
                    "desktopPage": 1
                },
                {
                    "type": "label",
                    "name": "<div><b>{cell} Scaldacqua a pompa di calore </b> </div>",
                    "cell": "C46",
                    "extraClass":"mt-0 scaldaquabox",
                    "desktopPage": 1
                },
                    {
                    "name": "troverai il dettaglio nel report finale",
                    "type": "label",
                    "desktopPage": 1,
                    "extraClass": "mt-0"
                }, 
            ]
        },
            {
                "title": "Televisore",
                "page": 4,
                "conditionalRendering": 
                [
                     {
                         "cell": "Final_TV",
                         "operator": "equal",
                         "value": true
                     }
                ],
                "items": [
                    {
                        "name": "Guardare una serie tv non è mai stato così bello!",
                        "type": "label",
                        "desktopPage": 1,
                        "extraClass": "no-margin mb-16"
                    }, 
                    {
                        "type": "box",
                        "extraTitleCell": "User_TV",
                        "extraClass": "yellow-tags mw730",
                        "title":"TV Samsung Crystal UHD 4k",
                        "serie":"Serie DU8075",
                        "tags": ["Processore Crystal 4k", "UHD", "Smart TV"],
                        "description":`<b>Il processore Crystal 4K</b> ti permette di godere di ogni sfumatura di colore, garantendo <b>immagini vivide</b> e <b>realistiche</b>. Con OTS Lite, <b>il suono ti immerge nell'azione</b>, facendoti sentire al centro di ogni scena. 
                                       Grazie a Motion Xcelerator, <b>l'esperienza di gioco</b> diventa straordinaria, con <b>movimenti fluidi e reattivi</b>. E con <b>Smart Hub</b>, hai tutti i tuoi contenuti preferiti raccolti in un unico posto, rendendo l'<b>accesso rapido e semplice.</b>`,
                        "desktopPage": 1,
                        "image": "TV_Pic",
                        "topChildren":  {
                            tipo: "Televisione",
                            big: 22,
                            comma: 90,
                            tan: "TAN 7.50% - TAEG 7.71%",
                            totale: "551,76",
                        } ,
                       
                        "required": false
                    },
                    {
                    "type": "choiceatview",
                    "cell":"User_TV",
                    "desktopPage": 1,
                    "name":"Seleziona la dimensione della TV",
                    "horizontal": "horizontal",
                    "choices": [
                    {
                        "text": "43\"",
                        "value":"Samsung Crystal UHD 43\""
                    },
                    {
                        "text": "55\"",
                        "value":"Samsung Crystal UHD 55\""
                    },
                   {
                        "text": "65\"",
                        "value":"Samsung Crystal UHD 65\""
                    }], 
                },
                {
                    "type":"divider",
                    "desktopPage": 1
                }
                ,
                 {
                    "type": "small-note",
                    "text": defaultNote,
                    // "text": `* Se sei già <b>cliente Enel</b> o decidi di diventarlo, hai accesso a <b>un prezzo speciale grazie al finanziamento a tasso zero</b>: TAN fisso 0,00% e TAEG 0,00%. Inoltre, per te c'è un bonus di 60€ in bolletta in 2 anni! E non preoccuparti, la <b>consegna al piano è inclusa nel prezzo.</b>
                    //            <br /> <br /> 
                    //             Se <b>non sei cliente Enel</b>, il tasso di finanziamento potrebbe variare. Se sei interessato ai nostri prodotti, parlane con il nostro <b>consulente nel punto vendita</b>. Sarà felice di darti tutte le informazioni sul finanziamento per aiutarti a capire il costo finale del prodotto che desideri. Ricorda di chiedere anche il documento <b>“Informazioni europee di base sul credito ai consumatori”</b> (SECCI) e una copia del contratto. In questo modo, avrai tutto il necessario per prendere una decisione informata sui costi e sulle condizioni.`,
                    "desktopPage": 1
                }
            ]
            }
            ,
            {
                "title": "Frigorifero",
                "page": 4,
                "conditionalRendering": 
                [
                     {
                         "cell": "Final_Frigorifero",
                         "operator": "equal",
                         "value": true
                     }
                ],
                "items": [
                    {
                        "name": "Pronto a dare una marcia in più alla tua cucina?",
                        "type": "label",
                        "desktopPage": 1,
                        "extraClass": "no-margin"
                    }, 
                    {
                        "type": "choiceatviewbox",
                        "cell":"User_Frigo",
                        "desktopPage": 1,
                        "extraClass": "yellow-tags",
                        "choices": [
                        {
                                "image":"Frigo_75",
                                "text": "Frigorifero Combinato Samsung F1rst 75 AI",
                                "subtitle": "Dimensioni: 75,9 x 203 x 71,1 cm",
                                "tags": ["Classe energetica A", "538L"],
                                "value":"Frigorifero Combinato Samsung F1rst 75 AI",
                                "footnote":"* I risultati del test si basano sul confronto tra la temperatura come da impostazioni di fabbrica con modalità AI Energy e senza modalità AI Energy. I risultati possono variare a seconda delle condizioni e delle modalità di utilizzo.",
                               "topChildren":  {
                                tipo:"Frigorifero",
                            big: 82,
                            comma: 99,
                            tan: "TAN 7.50% - TAEG 7.72%",
                            totale: "1.991,76",
                        } ,
                                // "bottomChildren": <div className="px-3 fw400" style={{ color: "black"}}>
                                //     <div aria-hidden={true}><DsyDivider /></div>
                                //     <p className="f14 mb-0 mt-2"><b>24 rate </b>mensile da</p>
                                //     <p className='f24 bold mb-0'>82,99 €*</p>
                                //     <p className="f14 mb-0 bold">TAN 7.50% - TAEG 7.72%</p>
                                //     <p className="f12 my-0">Totale <b>1.991,76 €</b></p>
                                //     <p className=" f12">IVA inclusa</p>
                                //     {/* <p className="f12">Prodotto disponibile solo con <br /> finanziamento tramite finanziaria</p> */}
                                // </div> ,
                                "description": "Grazie ai suoi 75cm di larghezza, <b>offre un’extra capacità di 538 litri</b> ed è dotato di funzioni esclusive che prolungano la freschezza degli alimenti Dotato di WIFI grazie alla funzione AI Energy Mode, permette di gestire e ottimizzare i consumi, <b>risparmiando fino al 15% * di energia.</b> La tecnologia <b>Twin Cooling Plus™</b> attraverso due circuiti di raffreddamento separati, mantiene costanti i livelli di umidità <b>preservando la freschezza degli alimenti fino a due volte più a lungo.</b> Inoltre, il rivestimento interno in acciaio trattiene il freddo e aiuta a contrastare eventuali oscillazioni di temperatura. A tutto questo si aggiunge il compressore <b>Digital Inverter</b> garantito 20 anni, che modula la potenza <b>evitando pericolosi sbalzi di temperatura e risparmiando energia.​.</b>"
                        },
                        {
                            "image":"Frigo_Bespoke",
                            "text": "Frigorifero Combinato Samsung BESPOKE AI",
                            "subtitle": "Dimensioni: 59,5 x 203 x 65,8 cm",
                            "tags": ["Classe energetica A -20%", "387L"],
                            "value":"Frigorifero Combinato Samsung BESPOKE AI",
                            "footnote":"*  Il consumo energetico di questo modello di [frigorifero con congelatore RB(L)38C***W**] è di 87kw/h/anno, con un'efficienza energetica superiore del 20% rispetto alla soglia minima della classe di efficienza energetica A (108,40 kWh/anno) per questo prodotto.",
                            "topChildren":
                            {
                                tipo:"Frigorifero",
                                big: 78,
                                comma: 99,
                                tan: "TAN 7.50% - TAEG 7.71%",
                                totale: "Totale 1.895,76 €",
                            } ,
                         
                            "description": "Con un design pulito e moderno e una profondità standard di 600mm, si integra perfettamente con gli altri mobili della cucina. <b>In classe energetica A-20%* garantisce un significativo risparmio energetico anche grazie alla modalità AI Energy Mode che monitora e riduce i consumi energetici fino al 15%.</b> La tecnologia <b>SpaceMax™</b> consente di aumentare lo spazio per la conservazione degli alimenti, a parità di dimensioni esterne. <b>Cool Select+</b> permette di personalizzare la temperatura, <b>ti basta premere un pulsante per trasformare il freezer in frigo.</b> Il rivestimento interno in acciaio trattiene il freddo e aiuta a contrastare eventuali oscillazioni di temperatura​"
                        },
                       
                        ]},
                    {
                        "type": "divider",
                        "desktopPage": 1,
                        "extraClass": "lg"
                    }, 
                    {
                        "type": "small-note",
                        "text": defaultNote,
                        "desktopPage": 1
                    }
                    // {
                    //     "type":"label",
                    //     "desktopPage": 1,
                    //     "extraClass":"lg",
                    //     "name": "<div class='description'>* I risultati del test si basano sul confronto tra la temperatura come da impostazioni di fabbrica con modalità AI Energy e senza modalità AI Energy. I risultati possono variare a seconda delle condizioni e delle modalità di utilizzo. <br />  <br /> ** La gamma di frigoriferi Samsung offre modelli dotati di tecnologie avanzate come il sistema di isolamento Air Space e il compressore Digital Inverter garantito 20 anni, che fanno risparmiare fino al 10% in più rispetto alla classe A. </div>"
                    // }
                ]
            }
        ,
        // {
        //     "title": "Piano Cottura cibi",
        //     "page": 4,
        //     "conditionalRendering": 
        //     [
        //          {
        //              "cell": "C48",
        //              "operator": "equal",
        //              "value": true
        //          }
        //     ],
        //     "items":[
        //         {
        //             "pre": "In base alle informazioni fornite sull'abitazione ti proponiamo:",
        //             // "unit":"Piano di cottura a induzione libera",
        //             "name": "Voglio modificare la configurazione",
        //             "placeholder": "",
        //             "cellSiNo": "C48Test",
        //             "cell": "D48_val",
        //             "response": "D48",
        //             "type": "optional",
        //             "canBeNull": true,
        //             "desktopPage": 1,
        //             "disclaimer": "*Potrebbe essere necessario effettuare successivi approfondimenti al fine di identificare la migliore configurazione possibile per il tuo edificio (se ci sono stanze comunicanti, vincoli ambientali o tecnici).",
        //             "choices": ["59.2cm","60cm", "75cm"]   
        //         },   
        //         // {
        //         //     "name": "Livello di prestazione",
        //         //     "cell": "PrestazioneCottura",
        //         //     "type": "choice",
        //         //     "choices": [
        //         //         "Più economico",
        //         //         "Miglior rapporto qualità/prezzo €€",
        //         //         "Top di gamma €€€"
        //         //     ],
        //         //     "conditionalRendering": 
        //         //     [
        //         //          {
        //         //              "cell": "C123132DaCambiare",
        //         //              "operator": "notequal",
        //         //              "value": false
        //         //          }
        //         //     ],
        //         //     "desktopPage": 1   
        //         // }
        //     ]
        // },
        {
            "title": "Fotovoltaico",
            "page": 4,
            // "notCondo": true,
            "conditionalRendering": 
            [
                {
                    "cell": "C11",
                    "operator": "notin",
                    "value": ["Monofamiliare", "Bifamiliare"]
                },
                 {
                     "cell": "C53",
                     "operator": "equal",
                     "value": true
                 },
                 {
                    "cell": "C54",
                    "operator": "equal",
                    "value": true
                }
            ],
            "items":[
                {
                    "name": "Puoi scegliere la tipologia di fotovoltaico che preferisci:",
                    "type": "label",
                    "desktopPage": 1,
                    "extraClass": "no-margin lg"
                }, 
                {
                    "type": "choiceatviewbox",
                    "extraClass": "yellow-tags vertical-tags",
                    "cell":"User_Foto",
                    "desktopPage": 1,
                    "choices": [
                    {
                        "image":"Foto_Batteria",
                        "text": "Pannelli solari con batteria",
                        "subtitle": "Fotovoltaico da tetto",
                        "tags": ["Modulo: JA Solar 1755x1040x30 mm", "Potenza: 0,41kWp / modulo", "Batteria: Fox-Ess"],
                        "value":"C54",
                        "topChildren": {
                            tipo:"Pannelli solari con batteria",
                            big: "9.390",
                            comma: "00",
                            tan: "oppure paga a rate",
                        } ,
                        "description": "L’impianto fotovoltaico con sistema di accumulo (batteria) è una soluzione completa. Ideale per chi è alla ricerca di semplicità e convenienza, <b> consente di accumulare l'energia solare prodotta e non consumata durante il giorno per utilizzarla anche quando l'impianto non è in funzione, ad esempio nelle ore notturne.</b>"
                    },
                    {
                        "image":"Foto_Solare",
                        "text": "Pannelli solari",
                        "subtitle": "Fotovoltaico da tetto",
                        "tags": ["Modulo: JA Solar 1755x1040x30 mm", "Potenza: 0,41kWp / modulo"],
                        "value":"C53",
                        "topChildren": {
                            tipo:"Pannelli solari",
                            big: "4.000",
                            comma: "00",
                            tan: "oppure paga a rate",
                        } ,
                        "description": "Ideale per chi è alla ricerca di semplicità e convenienza, <b>consente di risparmiare sulla bolletta grazie all'autoconsumo.</b> L'offerta è completa di tutti gli accessori necessari al funzionamento e comprende installazione, gestione delle pratiche amministrative e smaltimento dei moduli a fine vita."
                    },
                    ]},
                {
                    "name": "Qual è l'investimento più adatto a te?",
                    "type": "label",
                    "desktopPage": 1   
                },
                {
                    "name": "Opzioni di investimento",
                    "cell": "PrestazionePennelli",
                    "type": "choicebuttons",
                    "above":true,
                    "choices": [
                        "Più conveniente",
                        "Miglior rapporto qualità/prezzo €€",
                        "Top di gamma €€€"
                    ],
                    "desktopPage": 1   
                },
                {
                    "type": "small-note",
                    "desktopPage": 1,
                    "text": "* Progettazione e installazione, supporto pratica ENEA, oneri di connessione, configurazione del sistema di monitoraggio, verifiche e collaudo, pulizia aree di lavoro, smaltimento imballaggi e rifiuti prodotti con l’attività, dichiarazione di conformità, oneri di sicurezza e smaltimento moduli sono inclusi nel prezzo."     
                }
            ]
        },
        {
            "title": "Fotovoltaico",
            "page": 4,
            // "notCondo": true,
            "conditionalRendering": 
            [
                {
                    "cell": "C11",
                    "operator": "notin",
                    "value": ["Piccolo Condominio (2-8 abitazioni)", "Medio Condominio (9-15 abitazioni)", "Grande Condominio (oltre 16 abitazioni)"]
                },
                 {
                     "cell": "C53",
                     "operator": "equal",
                     "value": true
                 },
                 {
                    "cell": "C54",
                    "operator": "equal",
                    "value": true
                }
            ],
            "items":[
                {
                    "name": "Ecco l’impianto fotovoltaico perfetto per la tua casa!",
                    "type": "label",
                    "desktopPage": 1,
                    "extraClass": "no-margin lg mb-3"
            }, 
                {
                    "type": "box",
                    // "extraTitleCell": "User_TV",
                    "extraClass": "yellow-tags mb-3",
                    "title":"Sun Plug & Play",
                    "serie":"",
                    "tags": ["Modulo: 1770x1053x30mm", "Potenza: 0,37 kWp"],
                    "description":``,
                    "desktopPage": 1,
                    "image": "TBD",
                    "description": "Ideale per chi desidera un impianto fotovoltaico, <b> ma vive in un appartamento </b>. Semplice e veloce da installare sul tuo balcone in muratura, sotto la tua finestra oppure su una tua parete, <b>si connette alla rete tramite una spina elettrica</b> con una presa di corrente dedicata che rispetta i requisiti espressi dalla norma CEI 0-21, permettendo un immediato <b> risparmio sui consumi elettrici della casa.</b>",
                    "topChildren": {
                        tipologia: "Fotovoltaico da parete",
                        big: "539",
                        comma: "00",
                        tan: "oppure paga in rate"
                    },
                    "required": false
                },
                // {
                //     "type":"small-note",
                //     "desktopPage": 1,
                //     "text": "* Consegna bordo strada inclusa nel prezzo."
                // },
                // {
                //     "type":"small-note",
                //     "desktopPage": 1,
                //     "text": "** Sono disponibili diverse modalità di finanziamento. Per conoscere i requisiti di accesso alle agevolazioni, il periodo di validità e le spese ammissibili, consulta la normativa di riferimento o parla con il nostro consulente nel punto vendita. Sarà felice di darti tutte le informazioni necessarie per aiutarti a comprendere il costo finale del prodotto che desideri. Non dimenticare di richiedere il documento “Informazioni europee di base sul credito ai consumatori” (SECCI) e una copia del contratto, così avrai tutto il necessario per prendere una decisione informata sui costi e sulle condizioni."
                // }
            ]
        },
        {
            "title": "Solare Virtuale",
            "page": 4,
            "conditionalRendering": 
            [
                {
                    "cell": "FotoVirtuale",
                    "operator": "equal",
                    "value": true
                }
            ],
            "items":[
                {
                    "name": "Non puoi installare un pannello fotovoltaico? Abbiamo una soluzione per te!",
                    "type": "label",
                    "desktopPage": 1,
                    "extraClass": "no-margin lg mb-3"
                }, 
                {
                     "type": "boxfotovirtuale",
                     "desktopPage": 1,
                }                    
            ]
        },
        {
            "title": "La potenza del tuo contatore",
            "page": 5,        
            "items":[
                {
                    "box": true,
                    "name": "Scegli la nuova potenza del tuo contatore",
                    "placeholder": "",
                    "cellSiNo": "C84",
                    "cell": "D84",
                    "response": "C82",
                    "type": "optional",
                    "step": 0.5,
                    "min": 3,
                    "max": 10,
                    "numbertype": "input",
                    "numberCell":"D84",
                    "unit": "kW",
                    "canBeNull": true,
                    "desktopPage": 1   
                }, 
            ],
            "onSubmit": sendExtra,
        },
        {
            "title": "Fine",
            "items": []
        }
    ]
}

export default data;